.project,
.project-header {
  background-color: #040404;
  color: #fff;
}

.project-infos {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  text-align: center;
  margin: 20% 0 10% 0;
}

.project-underline {
  border-bottom: #fff solid 2px;
  padding: 0 0 8px 0;
  margin: 0 0 10px 0;
}

.project-keywords {
  padding-bottom: 20px;
}

.project-carousel {
  padding-bottom: 90px;
}

@media (min-width: 768px) {
  .project-index {
    padding-top: 64px;
  }
  .project-carousel {
    padding-bottom: 90px;
  }
}
