.header-chapter {
  background-color: #040404;
}

.header-chapter-negative {
  background-color: #fff;
}

.chapter-box-black {
  background-color: #040404;
  color: #fff;
}

.chapter-box a {
  cursor: pointer;
}

.chapter img {
  width: 85%;
  margin: 0 7% 0 7%;
}

.chapter-project-info {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  margin: 0 10% 10% 10%;
}

/* Bouton */
.chapter-cta {
  border-radius: 2;
  border: #040404 1px solid;
  background: none;
  color: #0f0f0f;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  padding: 15px 48px;
  margin: 0 25% 16% 25%;
}

.chapter-cta-reverse {
  border: #fff solid 1px;
  color: #fff;
}

.chapter-cta:hover {
  background-color: #8976ff;
  color: #fff;
  border: #fff 1px solid;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  cursor: pointer;
}

@media (min-width: 768px) {
  .chapter-project-info {
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin: 5% 0 15% 0;
  }
  .chapter-cta {
    margin: 0 0 16% 35%;
  }
  .chapter-box-black .chapter-project-info {
    text-align: left;
  }
  .chapter-cta-reverse {
    margin: 0 0 16% 0;
  }
  .mo-anubis-info,
  .mo-beyler-info {
    padding-right: 55%;
  }
  .vi-box-blk {
    padding-bottom: 5%;
  }
}

@media (min-width: 992px) {
  .chapter-project-info {
    font-size: 20px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin: 5% 0 15% 35%;
  }
  .chapter-cta {
    margin: 0 0 0 50%;
    font-size: 20px;
    padding: 24px 80px;
  }
  .chapter-box-black .chapter-project-info {
    text-align: left;
    margin: 5% 40% 15% 0;
  }
  .chapter-cta-reverse {
    margin: 0 0 16% 0;
  }
  .mo-anubis-info,
  .mo-beyler-info {
    padding-right: 155px;
  }
  .vi-box-blk {
    padding-bottom: 8%;
    margin-top: 10%;
  }
}
