#contact {
  margin-top: 25px;
}

.contacts-title {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 32px;
  padding: 25px 0 25px 20px;
}

.contacts-find {
  display: flex;
  flex-direction: row;
}

.contacts-link-picto {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
}

.contacts-link-picto svg {
  margin-bottom: 5px;
}

.contacts-link {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 0 5px 20px;
}

/* Footer à partir de médium */
.contacts-find-ipadtop {
  display: flex;
  flex-direction: row;
}

.contacts-link-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* Footer à partir de médium */

.contacts p {
  font-size: 18px;
  display: flex;
  justify-content: center;
  padding: 15% 30% 15% 5%;
}

.contacts-copyrights {
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .contacts-find-ipadtop {
    display: none;
  }
}

@media (min-width: 768px) {
  .contacts-title {
    font-size: 25px;
    padding: 3% 0 2% 12%;
  }
  .contacts-find-phone {
    display: none;
  }
  .contacts-find-ipadtop {
    display: block;
  }
  .contacts p {
    font-size: 15px;
    display: flex;
    padding: 0;
  }
  .contacts-link-line a {
    padding-left: 10%;
  }
}
