/* Chemin de retour vers le home */
.home-wayback {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 55px 0 55px 25px;
}

.home-wayback svg {
  margin: 0 10px 0 20px;
}

.home-chapter {
  position: relative;
}

.home-box {
  margin-top: 30px;
}

/* Titre des chapitres */
.home-chapter h1 {
  position: absolute;
}

.home-title-motion {
  top: 35%;
  left: 15%;
}

.home-title-video {
  top: 37%;
  left: 36%;
}

.home-title-web {
  top: 32%;
  left: 9%;
  font-size: 30px;
}

@media (max-width: 768px) {
  .home-wayback-text {
    display: none;
  }
  .home-wayback svg {
    height: 36px;
    width: 32px;
  }
}

@media (min-width: 768px) {
  .home-wayback svg {
    margin-left: 120%;
  }
  .home-title-motion {
    top: 35%;
    left: 30%;
  }
  .home-title-video {
    top: 37%;
    left: 40%;
  }
  .home-title-web {
    top: 37%;
    left: 14%;
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  .home-wayback-text {
    font-size: 25px;
    padding-left: 4%;
  }
  .home-wayback svg {
    width: 32px;
    height: 28px;
  }
  .home-title-motion {
    margin-left: 10%;
    margin-right: 10%;
  }
  .home-title-video {
    margin-left: 5%;
    margin-right: 40%;
  }
  .home-title-web {
    margin-left: 18%;
    margin-right: 10%;
  }
}

.home-box-motion {
  background-image: url(../img/anubis-1.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 200px;
}

.home-box-video {
  background-image: url(../img/loup-1.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 200px;
}

.home-box-web {
  background-image: url(../img/eikonica-1.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 200px;
}

@media (min-width: 768px) {
  .home-box-motion,
  .home-box-video,
  .home-box-web {
    background-color: #020202;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    height: 200px;
  }
}

@media (min-width: 992px) {
  .home-box-motion,
  .home-box-video,
  .home-box-web {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    height: 300px;
  }
}
