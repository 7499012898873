.introduction {
  background-color: #040404;
  padding-top: 150px;
  position: relative;
}

.intro-firstname {
  font-size: 20px;
  font-weight: 800;
  margin-left: 2%;
  visibility: hidden;
}

.intro-menu-desktop nav {
  display: flex;
  justify-content: space-between;
}

.intro-menu-desktop a {
  font-family: "Barlow Condensed", sans-serif;
}

.intro-name {
  color: #8976ff;
}

.intro-me {
  color: #f4f4f4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  margin-bottom: 180px;
}

.intro-languages {
  color: #f4f4f4;
  font-size: 12px;
  font-weight: 350;
}

@media (min-width: 992px) {
  .intro-me {
    font-size: 40px;
    line-height: 50px; /* 125% */
    margin-bottom: 50px;
  }
  .intro-languages {
    font-size: 18px;
  }
}

/* Bouton CTA */
.introduction button {
  background: none;
  color: #f4f4f4;
  font-size: 12px;
  padding: 10px 15px;
  margin: 30px 0 20px 0;
  border: solid 1px #f4f4f4;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.introduction button:hover {
  background: #fff;
  color: #8976ff;
  border: #8976ff solid 1px;
  cursor: pointer;
  box-shadow: 0px 0px 150px -10px #fff;
}

@media (max-width: 768px) {
  .introduction button {
    display: none;
  }
}

@media (min-width: 992px) {
  .introduction button {
    font-size: 18px;
    padding: 10px 15px;
    margin: 0px 0 180px 0;
  }
}
/* Bouton CTA fin */

/* Flèche */
.intro-arrow {
  position: absolute;
  bottom: 10%;
  left: 35%;
}
@media (min-width: 768px) {
  .intro-arrow {
    left: 8%;
    bottom: 10%;
  }
}
@media (min-width: 992px) {
  .intro-arrow {
    left: 10%;
  }
}
