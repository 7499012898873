.personnal {
  background-color: #040404;
  color: #fff;
}

// .perso-photo {
//   width: 80%;
//   margin: 18% 10% 15% 10%;
// }

// @media (min-width: 768px) {
//   .perso-photo {
//     width: 100%;
//     margin: 0;
//   }
// }

.personnal h1 {
  padding: 0 0 80px 0;
}

.perso-start p,
.perso-skills p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  margin: 0 25px 50px 28px;
}

.perso-software-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-around;
}

.perso-software {
  font-size: 16px;
  font-weight: 800;
  border: solid 0.5px #fff;
  padding: 15px 17px;
  margin-bottom: 100px;
}

.perso-figma {
  padding: 12px 19px;
  width: 10%;
}
