#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}
.menu__btn {
  position: fixed;
  top: 40px;
  right: 40px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 2;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 2px;
  background-color: #8976ff;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  z-index: 1;
  top: 0;
  right: -100%;
  // width: 390px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #fff;
  box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.25s;
}
.menu__item {
  display: block;
  padding: 12px 45px 15px 24px;
  color: #8976ff;
  background-color: #ffffff;
  font-size: 50px;
  font-weight: 600;
  text-decoration: none;
  text-align: right;
  transition-duration: 0.25s;
}
.menu__item:hover {
  background-color: #8876ff;
  color: #fff;
}

@media (min-width: 768px) {
  .menu__box {
    width: 100%;
    height: 50.5%;
  }
  .menu__item {
    font-size: 30px;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .menu__btn {
    right: 50px;
    width: 36px;
    height: 36px;
  }
  .menu__box {
    width: 25%;
    height: 100%;
  }
  .menu__item {
    font-size: 35px;
  }
}
