/* ====================================
// css variables
// ==================================== */

:root {
}

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* ====================================
// base rules
// ==================================== */

// font-family: 'Barlow Condensed', sans-serif;
// font-family: 'Nunito Sans', sans-serif;

* {
  font-family: "Nunito Sans", sans-serif;
  scroll-behavior: smooth;
}

header {
  padding-top: 30px;
}

.header-home {
  background-color: #040404;
  color: #f4f4f4;
}

.header-chapter {
  background-color: #000000;
  color: #040404;
}

a {
  text-decoration: none;
  cursor: pointer;
}

h1 {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 60px rgba(0, 0, 0, 0.8);
  font-size: 40px;
  font-weight: 500;
  line-height: 110%; /* 44px */
  text-decoration-line: underline;
}

h2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 80%; /* 32px */
  letter-spacing: -1px;
  margin: 10% 0 15% 12%;
}

h3 {
  color: #0f0f0f;
  text-align: center;
  font-size: 23px;
  font-weight: 900;
  line-height: 120%; /* 27.6px */
  letter-spacing: 2.3px;
  text-transform: uppercase;
  margin: 35px 0 20px 0;
}

h4 {
  color: #f4f4f4;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  margin-left: 10%;
}

h5 {
  color: #f4f4f4;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  padding-top: 40px;
  padding-left: 10px;
  padding-bottom: 50px;
}

video {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.img-website-screenshot {
  width: 90%;
  margin: 0 4% 15%;
}

@media (min-width: 768px) {
  h2 {
    margin: 5% 0 8% 10%;
  }
  h3 {
    text-align: right;
  }
  h4 {
    font-size: 35px;
    margin-left: 10%;
  }
  .video {
    height: 50%;
  }
  .chapter-box-black {
    h3 {
      text-align: left;
    }
  }
  .img-website-screenshot {
    width: 80%;
    margin: 0 10% 10%;
  }
  .albaenights-vid {
    margin: 0 15% 5% 10%;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
  }
  .v-beyler {
    margin: 0 20% 5% 20%;
    width: 60%;
  }
  .woods-vid {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .img-website-screenshot {
    width: 60%;
    margin: 0 20% 2%;
  }
  h3 {
    font-size: 40px;
  }
  h4 {
    font-size: 35px;
    margin-left: 9%;
  }
  .v-beyler {
    width: 40%;
    margin: 0 30% 2%;
  }
  .box-img {
    margin-bottom: 15%;
  }
}

footer {
  background: #8976ff;
  color: #fff;
}
