.carousel-container {
  position: relative;
  overflow: hidden;
  max-width: 400px; /* Ajuste la largeur maximale selon tes besoins */
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.project-gallery img {
  width: 100%;
  /* Rend toutes les images partiellement transparentes */
  transition: opacity 0.5s ease-in-out;
  height: auto;
  object-fit: contain; /* Empêche la compression des images */
}

.project-gallery img.active {
  opacity: 1; /* Rend l'image active complètement visible */
}

.prev-btn,
.next-btn {
  position: absolute;
  bottom: -3%;
  z-index: 2;
  background-color: #040404;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev-btn {
  left: 20%; /* Ajuste la position à gauche */
}

.next-btn {
  right: 20%; /* Ajuste la position à droite */
}

@media (max-width: 576px) {
  .prev-btn,
  .next-btn {
    bottom: -20%;
  }
}

@media (max-width: 768px) {
  .prev-btn,
  .next-btn {
    visibility: hidden;
  }
}

@media (min-width: 786px) {
  .carousel-container {
    max-width: 600px; /* Ajuste la largeur maximale selon tes besoins */
  }
}

@media (min-width: 992px) {
  .prev-btn,
  .next-btn {
    display: block;
  }
}
