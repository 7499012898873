.mo-albae-info h3 {
  color: #fff;
}

.mo-albae-img,
.mo-beyler-img {
  padding-top: 15%;
}

// .albaenights-vid {
//   height: 30%;
// }
